import { useMemo } from "react";
import useEvaluationContext from "./useEvaluationContext";
import { evaluate } from "@code2io/fe-engine/dist/common-flow-utils";

const useEvaluate = (expression: unknown) => {
  const { evaluationContext } = useEvaluationContext();

  return useMemo(() => {
    try {
      return (
        typeof expression === "string" &&
        evaluate(`{{${expression}}}`, evaluationContext)
      );
    } catch (e) {
      console.warn(e);
    }
  }, [expression, evaluationContext]);
};

export default useEvaluate;
