import type { SignupForm } from "../../features/auth/SignUp";
import { api } from "./api";

interface Token {
  access_token: string;
  token_type: string;
  expires_in: number;
}

interface EmailRequest {
  createdAt: string;
  expiresAt: string;
  id: string;
  newEmail: string;
  requestHashCode: string;
  userId: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRefreshToken: builder.query<Token, void>({
      query: () => "/jwt/refresh",
      providesTags: ["Auth", "AuthRefresh"]
    }),
    logout: builder.mutation<void, void>({
      query: () => "/login/logout",
      invalidatesTags: ["Auth"]
    }),
    sendResetPasswordEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: "POST",
        url: `/auth/send-password-reset-email`,
        body: {
          email
        }
      })
    }),
    sendVerificationEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: "POST",
        url: `/auth/send-verification-email`,
        body: {
          email
        }
      })
    }),
    resetPassword: builder.mutation<void, { password: string; code: string }>({
      query: ({ password, code }) => ({
        method: "POST",
        url: `/auth/password-reset`,
        body: {
          password,
          code
        }
      })
    }),
    passwordChangeRequest: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: `/auth/user/password-change-request`,
        body: {}
      })
    }),
    emailChangeRequest: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: "POST",
        url: `/auth/user/email-change-request`,
        body: {
          newEmail: email
        }
      }),
      invalidatesTags: ["EmailRequest"]
    }),
    getEmailChangeRequest: builder.query<EmailRequest | null, void>({
      query: () => `/auth/user/email-change-request`,
      providesTags: ["EmailRequest"]
    }),
    cancelEmailChangeRequest: builder.mutation<void, void>({
      query: () => ({
        method: "DELETE",
        url: `/auth/user/email-change-request`
      }),
      invalidatesTags: ["EmailRequest"]
    }),
    signUp: builder.mutation<void, SignupForm>({
      query: ({ firstName, lastName, email }) => ({
        method: "POST",
        url: `/signup/basic`,
        body: {
          email,
          firstName,
          lastName
        }
      })
    }),
    login: builder.mutation<void, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: "POST",
        url: `/login/basic`,
        body: {
          email,
          password
        }
      })
    }),
    impersonateUser: builder.mutation<
      void,
      { userId?: string; appId?: string; email?: string }
    >({
      query: ({ userId, appId, email }) => ({
        method: "POST",
        url: `/login/impersonate`,
        body: {
          impersonatedUserEmail: email,
          impersonatedUserId: userId,
          impersonatedUserAppId: appId
        }
      })
    }),
    unimpersonateUser: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: `/login/unimpersonate`
      })
    }),
    deleteAccount: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: `/auth/user/delete-account`,
        body: {}
      })
    })
  })
});

export const {
  useGetRefreshTokenQuery,
  useLogoutMutation,
  useSendResetPasswordEmailMutation,
  useResetPasswordMutation,
  usePasswordChangeRequestMutation,
  useEmailChangeRequestMutation,
  useSignUpMutation,
  useLoginMutation,
  useSendVerificationEmailMutation,
  useGetEmailChangeRequestQuery,
  useCancelEmailChangeRequestMutation,
  useImpersonateUserMutation,
  useUnimpersonateUserMutation,
  useDeleteAccountMutation
} = authApi;
