import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import type { Nullable } from "../../types";
import type { IFlowActionFormError } from "./components/PropertyEditor/ScheduleSettings/types";

export interface IFlowState {
  selectedNode: Nullable<string>;
  selectedFlow: Nullable<string>;
  errors: IFlowActionFormError[];
  showErrors: boolean;
  createExecutables: boolean;
  flowDesignerHeight: number;
}

const initialState: IFlowState = {
  selectedNode: null,
  selectedFlow: null,
  errors: [],
  showErrors: false,
  createExecutables: false,
  flowDesignerHeight: 0
};

export const flowSlice = createSlice({
  name: "flowDesigner",
  initialState,
  reducers: {
    resetFlowDesignerState: (state) => {
      state.selectedFlow = null;
      state.selectedNode = null;
    },
    setSelectedNode: (
      state,
      action: PayloadAction<IFlowState["selectedNode"]>
    ) => {
      state.selectedNode = action.payload;
    },
    setSelectedFlow: (
      state,
      action: PayloadAction<IFlowState["selectedFlow"]>
    ) => {
      state.selectedNode = null;
      state.selectedFlow = action.payload;
    },
    setErrors: (state, action: PayloadAction<IFlowState["errors"]>) => {
      state.errors = action.payload;
    },
    setShowErrors: (state, action: PayloadAction<boolean>) => {
      state.showErrors = action.payload;
    },
    setCreateExecutables: (state, action: PayloadAction<boolean>) => {
      state.createExecutables = action.payload;
    },
    setFlowDesignerHeight: (state, action: PayloadAction<number>) => {
      state.flowDesignerHeight = action.payload;
    }
  }
});

export const {
  setSelectedNode,
  setSelectedFlow,
  resetFlowDesignerState,
  setErrors,
  setShowErrors,
  setCreateExecutables,
  setFlowDesignerHeight
} = flowSlice.actions;

export const selectSelectedNode = (state: RootState) =>
  state.flowDesigner.selectedNode;

export const selectSelectedFlow = (state: RootState) =>
  state.flowDesigner.selectedFlow;

export const selectErrors = (state: RootState) => state.flowDesigner.errors;

export const selectShowErrors = (state: RootState) =>
  state.flowDesigner.showErrors;

export const selectCreateExecutables = (state: RootState) =>
  state.flowDesigner.createExecutables;

export const selectFlowDesignerHeight = (state: RootState) =>
  state.flowDesigner.flowDesignerHeight;

export default flowSlice.reducer;
