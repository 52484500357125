interface ConfigurationItem {
  name: string;
  advanced: boolean;
  description: string;
  label: string;
  nested: ConfigurationItem[] | null;
  required: boolean;
  type: string;
  visible: boolean;
  options?: ConfigurationDataOptions[];
}

interface ConfigurationDataOptions {
  value: string;
  label: string;
  nested?: ConfigurationDataOptions;
}

export interface DynamicDropDownValue {
  value: unknown;
  name: string;
}

export interface IConnection {
  authorizationType: string;
  configuration?: ConfigurationItem[];
  connectionType: ConnectionType | string;
  connectionTypeLabel?: string;
  groupCode: ConnectionType | string;
  groupLabel?: string;
  credentialSchemaType: string;
  category: string;
  name?: string;
  provider: string;
  id?: string;
  custom?: boolean;
  customProps?: { [key: string]: unknown };
  extraParams?: string;
  documentationUrl?: string;
  stage: ConnectionStage;
  earlyAccessRequested?: boolean;
  description?: string;
  dataCatalogSupported: boolean;
  redirectRequired?: boolean;
  catalogType?: string;
}

export enum ConnectionStage {
  Beta = "BETA",
  EarlyAccess = "EARLY_ACCESS",
  Production = "PRODUCTION"
}

export enum ConnectionType {
  GoogleAnalytics = "google_analytics",
  Excel365 = "excel_365",
  GoogleSheets = "google_sheets"
}

export interface GoogleSheetsExtraCredentials {
  accessToken: string;
  clientId: string;
  apiKey: string;
}

export interface Excel365ExtraCredentials {
  accessToken: string;
  clientId: string;
}

export interface GoogleSheetsDefinitions {
  sheets: GoogleSheetsDefinition[];
}

export interface GoogleSheetsDefinition {
  id: string;
  name: string;
}

export interface MicrosoftGraphApiResult {
  "@odata.context": string;
  "@odata.count": number;
  "@microsoft.graph.tips": string;
  value: MicrosoftGraphApiResultValue[];
}

export interface MicrosoftGraphApiResultValue {
  id: string;
  name: string;
  folder?: MicrosoftGraphApiResultFolder;
  file?: MicrosoftGraphApiResultFile;
}

export interface MicrosoftGraphApiResultFolder {
  childCount: number;
  view: MicrosoftGraphApiResultView;
}

export interface MicrosoftGraphApiResultView {
  viewType: string;
  sortBy: string;
  sortOrder: string;
}

export interface MicrosoftGraphApiResultFile {
  mimeType: string;
  hashes: MicrosoftGraphApiResultHashes;
}

export interface MicrosoftGraphApiResultHashes {
  quickXorHash: string;
  sha1Hash: string;
  sha256Hash: string;
}
