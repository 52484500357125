import type { INodeSize } from "./types";

const NodeTypes = {
  Trigger: "trigger",
  BackendTrigger: "backendTrigger",
  Action: "action",
  Adder: "adder",
  Switch: "switch",
  Callable: "callable",
  Fail: "fail",
  Success: "success",
  End: "end",
  ReusableFlow: "reusableFlow",
  Loop: "loop",
  EndLoop: "endLoop",
  Break: "break",
  SwitchCase: "switchCase",
  DefaultCase: "defaultCase"
} as const;

export const FlowTypes = {
  REUSABLE: "reusable",
  WEBHOOK: "webhook",
  SCHEDULED: "scheduled"
};

type INodeType = (typeof NodeTypes)[keyof typeof NodeTypes];

const EdgeTypes = {
  Adder: "adder"
} as const;

type IEdgeType = (typeof EdgeTypes)[keyof typeof EdgeTypes];

const NODE_SIZES: {
  [type in INodeType]: INodeSize;
} = {
  [NodeTypes.Action]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Trigger]: {
    width: 192,
    height: 50
  },
  [NodeTypes.BackendTrigger]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Adder]: {
    width: 24,
    height: 24
  },
  [NodeTypes.Switch]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Callable]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Fail]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Success]: {
    width: 156,
    height: 50
  },
  [NodeTypes.End]: {
    width: 156,
    height: 50
  },
  [NodeTypes.Break]: {
    width: 156,
    height: 50
  },
  [NodeTypes.ReusableFlow]: {
    width: 160,
    height: 50
  },
  [NodeTypes.Loop]: {
    width: 156,
    height: 50
  },
  [NodeTypes.EndLoop]: {
    width: 132,
    height: 50
  },
  [NodeTypes.SwitchCase]: {
    width: 75,
    height: 32
  },
  [NodeTypes.DefaultCase]: {
    width: 75,
    height: 32
  }
} as const;

const ConstantNodeIds = {
  TriggerNode: "triggerNode",
  SuccessNode: "successNode",
  EndNode: "endNode"
} as const;

const GraphLayoutSpacing = {
  marginX: 20,
  marginY: 60,
  rankSeperation: 48,
  edgeSeperation: 54
} as const;

export {
  NodeTypes,
  EdgeTypes,
  NODE_SIZES,
  ConstantNodeIds,
  GraphLayoutSpacing
};

export type { INodeType, IEdgeType };

// readonly, just for autocomplete scope
export const exampleWebhookRequestObj = {
  request: {
    pathVariables: {},
    matrixVariables: {},
    queryParameters: {},
    fragment: {},
    url: {},
    httpMethod: {},
    headers: {},
    body: {}
  }
} as const;

export const exampleDatatableEventObj = {
  input: {
    rowData: {}
  }
};
