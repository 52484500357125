import type { Nullable } from "../../types";
import { NodeTypes } from "./constants";
import { getEndLoopNodeIdFor, getStartNode } from "./data";
import { notNull } from "./typeGuards";
import type {
  IBackendFlow,
  IFlow,
  IFrontendFlow,
  INode,
  INodeData,
  IReusableFlow,
  NodeMap
} from "./types";
import { TerminalNodeTypes } from "./types";

function isTerminalAction(actionType: INodeData["actionType"]) {
  return TerminalNodeTypes.includes(actionType);
}

function isTerminalNode(node: INode<INodeData>) {
  return TerminalNodeTypes.includes(node.data.actionType);
}

function isSwitchNode(node: INode<INodeData>) {
  return node.type === NodeTypes.Switch;
}

function isLoopNode(node: INode<INodeData>) {
  return node.type === NodeTypes.Loop;
}

function isEndLoopNode(node: INode<INodeData>) {
  return node.type === NodeTypes.EndLoop;
}

function isFlow(obj: unknown): obj is IFlow {
  if (!obj) {
    return false;
  }
  if (typeof obj !== "object") {
    return false;
  }
  return (
    "type" in obj && ["fe", "be", "reusable"].includes((obj as IFlow).type)
  );
}

function isFrontendFlow(obj: unknown): obj is IFrontendFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "fe";
}

function isBackendFlow(obj: unknown): obj is IBackendFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "be";
}

function isReusableFlow(obj: unknown): obj is IReusableFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "reusable";
}

function closestEndNodeId(
  node: INode<INodeData>,
  nodes: NodeMap
): Nullable<string> {
  if (node.type === NodeTypes.EndLoop) {
    return node.id;
  }
  const start = getStartNode(nodes);
  let loopIdList: Nullable<string>[] = [];
  const queue = [start];

  while (queue.length > 0) {
    const curr = queue.shift()!;
    if (curr.type === NodeTypes.Loop) {
      loopIdList = [...loopIdList, getEndLoopNodeIdFor(curr.id)];
    }
    if (curr.type === NodeTypes.EndLoop) {
      loopIdList = loopIdList.filter((id) => id !== curr.id);
    }
    if (curr.id === node.id) {
      return loopIdList.length > 0 ? loopIdList[loopIdList.length - 1] : null;
    }
    curr.next.filter(notNull).forEach((id) => queue.push(nodes[id]));
  }
  return null;
}

function isSubflowNode(node: INode<INodeData>, nodes: NodeMap): boolean {
  const start = getStartNode(nodes);
  let level = 0;
  const queue = [start];

  while (queue.length > 0) {
    const curr = queue.shift()!;
    if (curr.type === NodeTypes.Loop) {
      level++;
    }
    if (curr.type === NodeTypes.EndLoop) {
      level--;
    }
    if (curr.id === node.id) {
      break;
    }
  }
  return level > 0;
}

export {
  isTerminalNode,
  isTerminalAction,
  isSubflowNode,
  closestEndNodeId,
  isSwitchNode,
  isLoopNode,
  isEndLoopNode,
  isFlow,
  isFrontendFlow,
  isBackendFlow,
  isReusableFlow
};
