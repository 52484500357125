import type { SVGIcon } from "../../app/components/Icon";
import type {
  Catalog,
  Column,
  GenericRestColumnMetadata,
  GenericRestMetadata,
  GenericRestSchemaMetadata,
  GenericRestTableMetadata,
  Schema,
  Table,
  VisualSQLQuery
} from "./types";
import { CatalogSubType, CatalogType, ColumnType } from "./types";
import { store } from "../../app/store";
import type { GetCatalogsQueryArg } from "../../app/services/catalog";
import customConnections from "./customConnections";
import type { IConnection } from "../connections/types";
import { ConnectionType } from "../connections/types";
import { faTimer, type IconDefinition } from "@fortawesome/pro-solid-svg-icons";

export const generateSchemaId = (schema: Schema) =>
  `${schema.catalogId}-${schema.schemaName}`;

export const generateTableId = (table: Table) =>
  `${table.catalogId}-${table.schemaName}-${table.tableName}`;

export const generateColumnId = (
  column: Pick<Column, "catalogId" | "schemaName" | "tableName" | "columnName">
) =>
  `${column.catalogId}-${column.schemaName}-${column.tableName}-${column.columnName}`;

export function isSame(arg1: Catalog, arg2: Catalog): boolean;
export function isSame(arg1: Schema, arg2: Schema): boolean;
export function isSame(arg1: Table, arg2: Table): boolean;
export function isSame(arg1: Column, arg2: Column): boolean;
export function isSame(arg1: unknown, arg2: unknown): boolean {
  if (isColumn(arg1) && isColumn(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId &&
      arg1.schemaName === arg2.schemaName &&
      arg1.tableName === arg2.tableName &&
      arg1.columnName === arg2.columnName
    );
  }

  if (isTable(arg1) && isTable(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId &&
      arg1.schemaName === arg2.schemaName &&
      arg1.tableName === arg2.tableName
    );
  }

  if (isSchema(arg1) && isSchema(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId && arg1.schemaName === arg2.schemaName
    );
  }

  if (isCatalog(arg1) && isCatalog(arg2)) {
    return arg1.id === arg2.id;
  }

  return false;
}

export function hasRelation(
  arg1: Catalog,
  arg2: Schema | Table | Column
): boolean;
export function hasRelation(arg1: Schema, arg2: Table | Column): boolean;
export function hasRelation(arg1: Table, arg2: Column): boolean;
export function hasRelation(arg1: unknown, arg2: unknown): boolean {
  if (isTable(arg1) && isColumn(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId &&
      arg1.schemaName === arg2.schemaName &&
      arg1.tableName === arg2.tableName
    );
  }

  if (isSchema(arg1) && isColumn(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId && arg1.schemaName === arg2.schemaName
    );
  }

  if (isSchema(arg1) && isTable(arg2)) {
    return (
      arg1.catalogId === arg2.catalogId && arg1.schemaName === arg2.schemaName
    );
  }

  if (isCatalog(arg1) && isColumn(arg2)) {
    return arg1.id === arg2.catalogId;
  }

  if (isCatalog(arg1) && isTable(arg2)) {
    return arg1.id === arg2.catalogId;
  }

  if (isCatalog(arg1) && isSchema(arg2)) {
    return arg1.id === arg2.catalogId;
  }

  return false;
}

export function getTableCacheKey(
  catalogId: string,
  schemaName: string,
  tableName: string
): string {
  return `${catalogId}-${schemaName}-${tableName}`;
}

export const encodeTableId = (table: Table): string => {
  return window.btoa(unescape(encodeURIComponent(JSON.stringify(table))));
};

export const decodeTableId = (tableId: string): Table | null => {
  try {
    const decodedValue = JSON.parse(
      decodeURIComponent(escape(window.atob(tableId)))
    ) as unknown;
    return isTable(decodedValue) ? decodedValue : null;
  } catch (e) {
    return null;
  }
};

export const isCatalog = (object: unknown): object is Catalog => {
  return typeof object === "object" && object != null && "id" in object;
};

export const isSchema = (object: unknown): object is Schema => {
  const keys = ["catalogId", "schemaName"];

  return (
    typeof object === "object" &&
    object != null &&
    keys.every((key) => key in object)
  );
};

export const isTable = (object: unknown): object is Table => {
  const keys = ["catalogId", "schemaName", "tableName"];

  return (
    typeof object === "object" &&
    object != null &&
    keys.every((key) => key in object)
  );
};

export const isColumn = (object: unknown): object is Column => {
  const keys = ["catalogId", "schemaName", "tableName", "columnName"];

  return (
    typeof object === "object" &&
    object != null &&
    keys.every((key) => key in object)
  );
};

export const isVisualSQLQuery = (object: unknown): object is VisualSQLQuery => {
  const keys = ["columns", "from", "filters", "limit", "offset", "orderBy"];

  return (
    typeof object === "object" &&
    object != null &&
    keys.every((key) => key in object)
  );
};

export const isInternal = (
  object: Column | Table | Schema | Catalog
): boolean => {
  const catalogId = isCatalog(object) ? object.id : object.catalogId;

  return catalogId === "1" || catalogId === "2";
};

export const isExternal = (
  object: Column | Table | Schema | Catalog
): boolean => {
  return !isInternal(object);
};

export const isDataRestTable = (catalog: Catalog): boolean => {
  return (
    catalog.type === CatalogType.GenericRest &&
    catalog.subType === CatalogSubType.TableExternalRest.toString()
  );
};

export const getCatalogIconProp = (
  catalogType: CatalogType,
  subType?: string,
  isBigTable?: boolean
): ["svg", SVGIcon] | ["fa", IconDefinition] => {
  switch (catalogType) {
    case CatalogType.Airtable:
      return ["svg", "airtable"];
    case CatalogType.BigQuery:
      return ["svg", "bigquery"];
    case CatalogType.BigQueryLegacy:
      return ["svg", "bigquery"];
    case CatalogType.Elasticsearch:
      return ["svg", "elasticsearch"];
    case CatalogType.Firebase:
      return ["svg", "firebase"];
    case CatalogType.GitHub:
      return ["svg", "github"];
    case CatalogType.Internal: {
      if (subType === "mt-query") {
        return ["fa", faTimer];
      } else {
        return [
          "svg",
          subType === "query"
            ? "internal-query"
            : isBigTable
            ? "internal-bi-table"
            : "internal-table"
        ];
      }
    }
    case CatalogType.Instagram:
      return ["svg", "instagram"];
    case CatalogType.GenericRest:
      return [
        "svg",
        subType === CatalogSubType.TableExternalRest.toString()
          ? "data-rest-logo"
          : ((subType ?? "code-2-logo").replaceAll("_", "-") as SVGIcon)
      ];
    case CatalogType.GoogleSheets:
      return ["svg", "google-sheets"];
    case CatalogType.HubSpot:
      return ["svg", "hubspot"];
    case CatalogType.MongoDB:
      return ["svg", "mongodb"];
    case CatalogType.Mongo:
      return ["svg", "mongodb"];
    case CatalogType.MySQL:
      return ["svg", "mysql"];
    case CatalogType.MSSQLLeagacy:
      return ["svg", "mysql"];
    case CatalogType.MariaDB:
      return ["svg", "mariadb"];
    case CatalogType.MariaDBLegacy:
      return ["svg", "mariadb"];
    case CatalogType.MSSQL:
      return ["svg", "mssql"];
    case CatalogType.MySQLegacy:
      return ["svg", "mssql"];
    case CatalogType.Neo4J:
      return ["svg", "neo4j"];
    case CatalogType.Oracle:
      return ["svg", "oracle"];
    case CatalogType.OracleLeagacy:
      return ["svg", "oracle"];
    case CatalogType.Postgres:
      return ["svg", "postgres"];
    case CatalogType.PostgresSQL:
      return ["svg", "postgres"];
    case CatalogType.SAPHANA:
      return ["svg", "sap"];
    case CatalogType.SAPHANALegacy:
      return ["svg", "sap"];
    case CatalogType.Snowflake:
      return ["svg", "snowflake"];
    case CatalogType.SnowflakeLegacy:
      return ["svg", "snowflake"];
    case CatalogType.SingleStore:
      return ["svg", "single-store"];
    case CatalogType.SingleStoreLegacy:
      return ["svg", "single-store"];
    case CatalogType.Stripe:
      return ["svg", "stripe"];
    case CatalogType.Dynamics365:
      return ["svg", "dynamics_365"];
    case CatalogType.PeakaJDBC:
      if (subType === CatalogSubType.Jira) {
        return ["svg", "jira"];
      } else {
        return ["svg", "code-2-logo"];
      }
    case CatalogType.SalesForceProgress:
      return ["svg", "salesforce"];
    case CatalogType.SalesForceProgressLegacy:
      return ["svg", "salesforce"];
    case CatalogType.Aurora:
      return ["svg", "aws-rds"];
    case CatalogType.AuroraLegacy:
      return ["svg", "aws-rds"];
    case CatalogType.Teradata:
      return ["svg", "teradata"];
    case CatalogType.TeradataLeagacy:
      return ["svg", "teradata"];
    case CatalogType.Databricks:
      return ["svg", "databricks"];
    case CatalogType.DatabricksLegacy:
      return ["svg", "databricks"];
    case CatalogType.ClickHouse:
      return ["svg", "clickhouse"];
    case CatalogType.ClickHouseLegacy:
      return ["svg", "clickhouse"];
    case CatalogType.UpstashVector:
      return ["svg", "upstashvector"];
    case CatalogType.Odoo:
      return ["svg", "odoo"];
    case CatalogType.Pinecone:
      return ["svg", "pinecone"];
    case CatalogType.Excel365:
      return ["svg", "excel365"];
    case CatalogType.Qdrant:
      return ["svg", "qdrant"];
    case CatalogType.Weaviate:
      return ["svg", "weaviate"];
    case CatalogType.Redshift:
      return ["svg", "redshift"];
    case CatalogType.RedshiftLegacy:
      return ["svg", "redshift"];

    default:
      return ["svg", "code-2-logo"];
  }
};

export const getCatalogName = (
  catalogType: CatalogType,
  catalogSubType?: string
) => {
  switch (catalogType) {
    case CatalogType.Airtable:
      return "Airtable";
    case CatalogType.Internal:
      return catalogSubType === "query" ? "Peaka Query" : "Peaka Table";
    case CatalogType.GoogleSheets:
      return "Google Sheets";
    case CatalogType.GenericRest:
      return (catalogSubType ?? "")
        .split("_")
        .map((part) => `${part[0].toUpperCase()}${part.slice(1)}`)
        .join(" ");
    case CatalogType.HubSpot:
      return "HubSpot";
    case CatalogType.MySQL:
      return "MySQL";
    case CatalogType.Postgres:
      return "PostgreSQL";
    case CatalogType.Stripe:
      return "Stripe";
    default:
      return "";
  }
};

const defaultTable: Table = {
  catalogId: "1",
  schemaName: "table",
  tableName: ""
};

export const getSelectAllVisualSQLQuery = (
  table: Table | undefined | null,
  config?: Partial<VisualSQLQuery>
): VisualSQLQuery => {
  if (table == null) {
    table = defaultTable;
  }

  return {
    columns: [
      {
        column: {
          catalogId: table.catalogId,
          schemaName: table.schemaName,
          tableName: table.tableName,
          columnName: "*",
          dataType: null,
          props: null
        },
        function: null
      }
    ],
    from: [table],
    filters: null,
    limit: 1000,
    offset: 0,
    orderBy: null,
    rows: null,
    ...config
  };
};

export const isEmptyVisualSQLQuery = (
  query: VisualSQLQuery | null
): boolean => {
  return (
    query == null ||
    (query.columns.length === 0 &&
      query.from.length === 0 &&
      query.filters == null &&
      query.orderBy == null &&
      query.rows == null)
  );
};

export const setGenericRestMetadataLocal = (
  genericRestMetadata: GenericRestMetadata
) => {
  localStorage.setItem(
    `peaka.meta.generic_rest.${genericRestMetadata.subType}`,
    JSON.stringify(genericRestMetadata)
  );
};

export const getGenericRestMetadataLocal = (
  subType: string
): GenericRestMetadata | null => {
  const localValue = localStorage.getItem(`peaka.meta.generic_rest.${subType}`);
  return localValue == null
    ? null
    : (JSON.parse(localValue) as GenericRestMetadata);
};

export const getGenericRestSchemaMetadata = ({
  appId,
  catalogId,
  schemaName
}: {
  appId: string;
  catalogId: string;
  schemaName: string;
}): GenericRestSchemaMetadata | null => {
  let genericRestSchemaMetadata: GenericRestSchemaMetadata | null = null;
  const catalog = getGenericRestCatalog(appId, catalogId);

  if (catalog != null) {
    const genericRestMetadata = getGenericRestMetadataLocal(catalog.subType);

    if (genericRestMetadata != null) {
      genericRestSchemaMetadata =
        genericRestMetadata.schemas.find(
          (schemaMetadata) => schemaMetadata.schemaName === schemaName
        ) ?? null;
    }
  }

  return genericRestSchemaMetadata;
};

export const getGenericRestTableMetadata = ({
  appId,
  catalogId,
  schemaName,
  tableName
}: {
  appId: string;
  catalogId: string;
  schemaName: string;
  tableName: string;
}): GenericRestTableMetadata | null => {
  const genericRestSchemaMetadata = getGenericRestSchemaMetadata({
    appId,
    catalogId,
    schemaName
  });

  return (
    genericRestSchemaMetadata?.tables.find(
      (genericRestTableMetadata) =>
        genericRestTableMetadata.tableName === tableName
    ) ?? null
  );
};

export const getGenericRestSchemas = ({
  appId,
  catalogId
}: {
  appId: string;
  catalogId: string;
}): Schema[] => {
  let schemas: Schema[] = [];
  const catalog = getGenericRestCatalog(appId, catalogId);

  if (catalog != null) {
    const genericRestMetadata = getGenericRestMetadataLocal(catalog.subType);

    if (genericRestMetadata != null) {
      schemas = genericRestMetadata.schemas.map(({ schemaName }) => ({
        catalogId,
        schemaName
      }));
    }
  }

  return schemas;
};

export const getGenericRestTables = ({
  appId,
  catalogId,
  schemaName
}: {
  appId: string;
  catalogId: string;
  schemaName: string;
}): Table[] => {
  const genericRestSchemaMetadata = getGenericRestSchemaMetadata({
    appId,
    catalogId,
    schemaName
  });

  return (
    genericRestSchemaMetadata?.tables.map((genericRestTableMetadata) =>
      convertGenericRestTableMetadataToTable({
        catalogId,
        schemaName,
        genericRestTableMetadata
      })
    ) ?? []
  );
};

export const getGenericRestColumns = ({
  appId,
  catalogId,
  schemaName,
  tableName
}: {
  appId: string;
  catalogId: string;
  schemaName: string;
  tableName: string;
}): Table[] => {
  const genericRestTableMetadata = getGenericRestTableMetadata({
    appId,
    catalogId,
    schemaName,
    tableName
  });
  return (
    genericRestTableMetadata?.columns.map((genericRestColumnMetadata, index) =>
      convertGenericRestColumnMetadataToColumn({
        catalogId,
        schemaName,
        tableName,
        index,
        genericRestColumnMetadata
      })
    ) ?? []
  );
};

function getGenericRestCatalog(
  appId: string,
  catalogId: string
): Catalog | null {
  const state = store.getState();
  const catalogs =
    (Object.values(state.api.queries).find(
      (query) =>
        query != null &&
        query.endpointName === "getCatalogs" &&
        (query.originalArgs as GetCatalogsQueryArg).appId === appId
    )?.data as Catalog[] | undefined) ?? [];
  const catalog = catalogs.find((catalog) => catalog.id === catalogId);
  return catalog?.type === CatalogType.GenericRest ? catalog : null;
}

export const convertGenericRestSchemaMetadataToSchema = (arg: {
  catalogId: string;
  genericRestSchemaMetadata: GenericRestSchemaMetadata;
}): Schema => {
  return {
    catalogId: arg.catalogId,
    schemaName: arg.genericRestSchemaMetadata.schemaName
  };
};

export const convertGenericRestTableMetadataToTable = (arg: {
  catalogId: string;
  schemaName: string;
  genericRestTableMetadata: GenericRestTableMetadata;
}): Table => {
  return {
    catalogId: arg.catalogId,
    schemaName: arg.schemaName,
    tableName: arg.genericRestTableMetadata.tableName,
    isDynamicTable: arg.genericRestTableMetadata.isDynamicTable,
    isCacheable: arg.genericRestTableMetadata.isCacheable
  };
};

export const convertGenericRestColumnMetadataToColumn = (arg: {
  catalogId: string;
  schemaName: string;
  tableName: string;
  index: number;
  genericRestColumnMetadata: GenericRestColumnMetadata;
}): Column => {
  return {
    catalogId: arg.catalogId,
    schemaName: arg.schemaName,
    tableName: arg.tableName,
    columnName: arg.genericRestColumnMetadata.columnName,
    dataType: arg.genericRestColumnMetadata.props.dataType,
    props: {
      ...arg.genericRestColumnMetadata.props,
      id: "-1",
      displayName: arg.genericRestColumnMetadata.columnName,
      name: arg.genericRestColumnMetadata.columnName,
      order: arg.index,
      isNotNull: false,
      isUnique: false,
      defaultValue: null,
      type: ColumnType.External
    }
  };
};

export const customConnectionCatalogTypes: string[] = [
  CatalogType.MongoDB.toString(),
  CatalogType.Weaviate.toString(),
  CatalogType.Qdrant.toString(),
  CatalogType.Pinecone.toString(),
  CatalogType.UpstashVector.toString(),
  CatalogType.Odoo.toString(),
  CatalogType.Firebase.toString(),
  CatalogType.Neo4J.toString(),
  CatalogType.Elasticsearch.toString(),
  CatalogType.Postgres.toString(),
  CatalogType.MySQL.toString(),
  CatalogType.Oracle.toString(),
  CatalogType.MSSQL.toString(),
  CatalogType.MariaDB.toString(),
  CatalogType.ClickHouse.toString(),
  CatalogType.Redshift.toString(),
  CatalogType.Aurora.toString(),
  CatalogType.Snowflake.toString(),
  CatalogType.Teradata.toString(),
  CatalogType.SingleStore.toString(),
  CatalogType.Databricks.toString(),
  CatalogType.BigQuery.toString(),
  CatalogType.SalesForceProgress.toString(),
  CatalogType.SAPHANALegacy.toString()
];

export const isNewCustomConnectionImpl = (connectionType: string) => {
  return customConnectionCatalogTypes.includes(connectionType);
};

export const isCustomConnection = (connectionType: ConnectionType | string) => {
  return customConnections.some((customConnection) => {
    return customConnection.connectionType === connectionType;
  });
};

export const hasExtraParams = (connectionType: ConnectionType | string) => {
  return (
    connectionType === ConnectionType.GoogleAnalytics ||
    connectionType === ConnectionType.Excel365
  );
};

export const convertCatalogToCustomConnection = (
  catalog: Catalog
): IConnection => {
  const customConnection = customConnections.find(
    (customConnection) => customConnection.connectionType === catalog.type
  );

  if (customConnection == null) {
    throw new Error("Invalid custom connection type");
  }

  return {
    ...customConnection,
    customProps: catalog.props
  };
};
