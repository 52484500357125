import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import type { User } from "../../features/auth/authSlice";
import { setToken, setUser } from "../../features/auth/authSlice";
import type { RootState } from "../store";
import jwt_decode from "jwt-decode";
import { getDynamicConfigValue } from "../utils/dynamicConfig";
import { EnvironmentConstants } from "../../environmentConstants";
import { refreshLock } from "../refreshLock";

const baseUrl = getDynamicConfigValue(EnvironmentConstants.STUDIO_API_URL);

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      headers.set("Authorization", `Bearer null`);
    }
    headers.set("X-Requested-With", "XMLHttpRequest");
    return headers;
  }
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await refreshLock.waitForUnlock();
  let result = await baseQuery(
    typeof args === "string"
      ? { url: args, credentials: "include" }
      : { ...args, credentials: "include" },
    api,
    extraOptions
  );
  if (result.error?.status === 401) {
    if (!refreshLock.isLocked()) {
      const release = await refreshLock.acquire();
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const refreshResult: ReturnType<typeof baseQuery> =
          await navigator.locks.request("refreshToken", async () => {
            return await baseQuery(
              { credentials: "include", url: `jwt/refresh` },
              api,
              extraOptions
            );
          });
        if ("data" in refreshResult) {
          const accessToken = (
            refreshResult as { data: { access_token: string } }
          ).data.access_token;
          api.dispatch(setToken(accessToken));
          const decodedToken: User = jwt_decode(accessToken);
          api.dispatch(setUser(decodedToken));
          result = await baseQuery(
            typeof args === "string"
              ? { url: args, credentials: "include" }
              : { ...args, credentials: "include" },
            api,
            extraOptions
          );
        } else {
          // log out
          window.location.href =
            "/login?redirectPath=" + window.location.pathname;
        }
      } finally {
        release();
      }
    } else {
      await refreshLock.waitForUnlock();
      result = await baseQuery(
        typeof args === "string"
          ? { url: args, credentials: "include" }
          : { ...args, credentials: "include" },
        api,
        extraOptions
      );
    }
  }
  return result;
};

export default customFetchBase;
