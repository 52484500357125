import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import "./index.css";
import "./primeFlexExtensions.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { getDynamicConfigValue } from "./app/utils/dynamicConfig";
import { EnvironmentConstants } from "./environmentConstants";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import FrigadeProvider from "./app/components/FrigadeOnboarding/FrigadeProvider";

window.addEventListener("error", (error) => {
  if (
    error.message.includes(
      "Cannot submit op. Document has not been created. queries."
    )
  ) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    return false;
  }
  return true;
});

if (getDynamicConfigValue(EnvironmentConstants.ENVIRONMENT) === "production") {
  console.log = () => {
    /* Do nothing */
  };
  console.warn = () => {
    /* Do nothing */
  };
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

if (getDynamicConfigValue(EnvironmentConstants.GA_ENABLED) == "true") {
  ReactGA.initialize(getDynamicConfigValue(EnvironmentConstants.GA_API_KEY));
  const tagManagerArgs = {
    gtmId: getDynamicConfigValue(EnvironmentConstants.GTM_API_KEY)
  };

  TagManager.initialize(tagManagerArgs);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        {/*

        <DoptOnboarding
          flowVersions={{
            "create-new-project": 0,
            "initial-flow": 0,
            studio: 0,
            welcome: 0,
            deneme: 0
          }}
        >
          <App />
        </DoptOnboarding>
      */}

        {getDynamicConfigValue(EnvironmentConstants.ONPREMISE) == "false" ? (
          <FrigadeProvider>
            <App />
          </FrigadeProvider>
        ) : (
          <App />
        )}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
