import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { SVGIcon } from "../../app/components/Icon";
import Icon from "../../app/components/Icon";
import type {
  IActionBase,
  IActionGroup,
  ICustomAction
} from "../../app/services/actions";
import { getComponentList } from "../../resources";
import ActionAdderEdge from "./components/edges/ActionAdderEdge";
import ActionAdderNode from "./components/nodes/ActionAdderNode";
import ActionNode from "./components/nodes/ActionNode";
import BreakNode from "./components/nodes/BreakNode";
import CallableNode from "./components/nodes/CallableNode";
import DefaultCaseNode from "./components/nodes/DefaultCaseNode";
import EndLoopNode from "./components/nodes/EndLoopNode";
import LoopNode from "./components/nodes/LoopNode";
import ReusableFlowNode from "./components/nodes/ReusableFlowNode";
import SwitchCaseNode from "./components/nodes/SwitchCaseNode";
import SwitchNode from "./components/nodes/SwitchNode";
import TriggerNode from "./components/nodes/TriggerNode";
import { ConstantNodeIds, type INodeType } from "./constants";
import type {
  ITriggerNodeData,
  IFlowType,
  IActionAdderMenuItemCommand,
  IActionAdderMenuItemData,
  IActionAdderMenuItem,
  INewComponentFlowConfig,
  IFrontendFlow,
  IBackendFlow,
  IReusableFlow,
  INodeData,
  NodeMap,
  INode,
  IActionType,
  IFlow
} from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowProgress,
  faArrowsRotate,
  faGlobe,
  faDiagramNested,
  faWebhook
} from "@fortawesome/pro-regular-svg-icons";
import type { WebhookMethodParameterOption } from "../flowsPage/types";
import { cloneDeep } from "lodash";
import { flowNodeDefaults } from "./nodeParameterDefinitions";
import { v4 as uuidv4 } from "uuid";
import { getFlowExecutions } from "./converter/getFlowExecutions";
import BackendTriggerNode from "./components/nodes/BackendTriggerNode";

function getLoopNodeIdFor(endNodeId: string): string {
  // find the id without `-end`
  return endNodeId.substring(0, endNodeId.length - 4);
}

function getEndLoopNodeIdFor(loopNodeId: string): string {
  return `${loopNodeId}-end`;
}

function isEndLoopId(test: string): boolean {
  return test.endsWith("-end");
}

function getStartNode(nodes: NodeMap): INode<INodeData> | undefined {
  const nodeIds = Object.keys(nodes);

  const id = nodeIds.find((id) => {
    return Object.values(nodes).every((node) => {
      return !node.next.includes(id);
    });
  });

  if (typeof id !== "undefined") {
    return nodes[id];
  }
}

function getTriggerNodeOptions(
  flowType: IFlowType,
  componentType = "Button",
  componentKey?: string,
  designMode: DesignMode = "advanced"
): ITriggerNodeData["options"] {
  const componentList = getComponentList(designMode);
  switch (flowType) {
    case "fe": {
      const componentDef = Object.values(componentList).find((def) => {
        return componentType === "C2Field"
          ? componentKey === def.base.key && componentType === def.base.type
          : componentType === def.base.type;
      });

      if (typeof componentDef === "undefined") return [];

      return componentDef.eventList.map((e) => ({
        value: e.name,
        label: e.readableName
      }));
    }
    case "be": {
      return [
        {
          label: "Webhook",
          value: "webhook"
        },
        {
          label: "Scheduled",
          value: "scheduled"
        }
      ];
    }

    default: {
      return [];
    }
  }
}

function getActionNodeMenuItems(
  type: IFlowType,
  groupsAndActions: IActionGroup,
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
): IActionAdderMenuItem<IActionAdderMenuItemData>[] {
  return [
    getCode2ActionsSubMenuItems(type, onSelect),
    getRecordsMenuItems(onSelect),
    ...(getUtilitiesSubMenuItems(onSelect)
      .items as IActionAdderMenuItem<IActionAdderMenuItemData>[]),
    getConnectionActionsSubMenuItems(groupsAndActions, onSelect)
  ];
}

function getRecordsMenuItems(
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
) {
  return {
    label: "Records",
    icon: "c2-icons-record",
    items: [
      {
        label: "Get a record",
        data: {
          actionType: "db:get",
          type: "action"
        },
        command: onSelect
      },
      {
        label: "Get all records",
        data: {
          actionType: "db:query",
          type: "action"
        },
        command: onSelect
      },
      {
        label: "Add a record",
        data: {
          actionType: "db:insert",
          type: "action"
        },
        command: onSelect
      },
      {
        label: "Add multiple records",
        data: {
          actionType: "db:bulkInsert",
          type: "action"
        },
        command: onSelect
      },
      {
        label: "Update a record",
        data: {
          actionType: "db:update",
          type: "action"
        },
        command: onSelect
      },
      {
        label: "Delete a record",
        data: {
          actionType: "db:delete",
          type: "action"
        },
        command: onSelect
      }
    ]
  };
}

function generateParams(
  inputs: { defaultValue: unknown; name: string; type: string }[]
) {
  const params: {
    [key: string]: unknown;
  } = {};
  inputs.forEach((input) => {
    params[input.name] = input.defaultValue || "";
  });
  return params;
}

export function generatePropDefs(inputs: { [key: string]: unknown }[]) {
  return inputs.map((input) => ({
    label: input.name,
    type: input.type,
    dataType: input.type,
    valKey: input.name
  }));
}

function getActionType(
  action: IActionBase
): `code2:action:${string}/${string}:${number}.${number}.${number}` {
  const splitted = action.resourceKey.split(":");
  splitted.shift();
  return splitted.join(
    ":"
  ) as `code2:action:${string}/${string}:${number}.${number}.${number}`;
}

function createNodeDef(action: IActionBase, group: string): INodeData {
  const actionObj = action.action;
  const executionType = action.resourceKey.split(":")[0] === "be" ? "be" : "fe";
  const nodeDef: INodeData = {
    label: action.action.label ? action.action.label : action.action.name,
    executionType,
    icon: group as SVGIcon,
    actionType: getActionType(action),
    params: generateParams(actionObj.inputs),
    output: {
      type: "void",
      properties: {}
    },
    variables: [],
    paramDefs: generatePropDefs(actionObj.inputs)
  };
  return nodeDef;
}

function getActionItemsByGroupName(
  groupArray: { action: ICustomAction; version: string; resourceKey: string }[],
  groupName: string,
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
): IActionAdderMenuItem<IActionAdderMenuItemData>[] {
  const items: IActionAdderMenuItem<IActionAdderMenuItemData>[] = [];

  for (const group of groupArray) {
    const actionObj = group;
    const nodeDef: INodeData = createNodeDef(actionObj, groupName);
    items.push({
      label: actionObj.action.label
        ? actionObj.action.label
        : actionObj.action.name,
      data: {
        actionType: "customAction",
        type: "action",
        nodeInfo: nodeDef
      },
      command: onSelect
    });
  }
  items.sort((a, b) => {
    if (a.label!.toLowerCase() < b.label!.toLowerCase()) {
      return -1;
    }
    if (a.label!.toLowerCase() > b.label!.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return items;
}

export const groupToSVGKey: {
  [key: string]: SVGIcon | undefined;
} = {
  Airtable: "airtable",
  "Google Analytics": "google-analytics",
  HubSpot: "hubspot",
  Intercom: "intercom",
  "Jira Cloud": "jira",
  Stripe: "stripe",
  "Mailchimp Marketing": "mailchimp-marketing",
  SendGrid: "sendgrid",
  "Mailchimp Transactional": "mailchimp-transactional",
  Slack: "slack",
  insider: "insider"
};

function getConnectionActionsSubMenuItems(
  groupsAndActions: IActionGroup | [],
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
): IActionAdderMenuItem<IActionAdderMenuItemData> {
  const menu: {
    label: string;
    icon: string;
    items: IActionAdderMenuItem<IActionAdderMenuItemData>[];
  } = {
    label: "Connections",
    icon: "c2-icons-text-hand-select",
    items: []
  };

  if (groupsAndActions.length === 0) return menu;

  for (const group in groupsAndActions) {
    menu.items.push({
      label: group,
      template: (item) => {
        return (
          // template copied from primereact's menu item
          <a
            href="#"
            className="p-menuitem-link"
            role="menuitem"
            aria-haspopup="false"
          >
            {
              <Icon
                className="mr-2"
                icon={["svg", groupToSVGKey[group] ?? "code-2-logo"]}
              />
            }
            <span className="p-menuitem-text">{item.label}</span>
            <span className="p-submenu-icon pi pi-angle-right" />
          </a>
        );
      },
      items: getActionItemsByGroupName(
        (groupsAndActions as IActionGroup)[group],
        group,
        onSelect
      )
    });
  }

  menu.items.push({
    label: "Insider",
    template: (item) => {
      return (
        // template copied from primereact's menu item
        <a
          href="#"
          className="p-menuitem-link"
          role="menuitem"
          aria-haspopup="false"
        >
          {
            <Icon
              className="mr-2"
              icon={["svg", groupToSVGKey.insider ?? "code-2-logo"]}
            />
          }
          <span className="p-menuitem-text">{item.label}</span>
          <span className="p-submenu-icon pi pi-angle-right" />
        </a>
      );
    },
    items: [
      {
        label: "Upsert",
        data: {
          actionType: "customAction",
          type: "action",
          nodeInfo: {
            label: "Upsert",
            executionType: "be",
            icon: "insider",
            actionType: "insider:upsert",
            params: {
              cursorColumnName: "",
              connection: "",
              tableId: "",
              columns: null,
              filters: null,
              from: [],
              orderBy: null,
              limit: 50,
              offset: 0,
              mapping: {
                identifiers: {
                  email: "",
                  uuid: "",
                  phone_number: ""
                },
                attributes: {
                  email_optin: "",
                  age: "",
                  language: "",
                  list_id: "",
                  birthday: ""
                },
                events: {
                  event_name: "purchase",
                  timestamp: "",
                  event_params: {
                    product_id: "",
                    name: "",
                    unit_price: "",
                    unit_sale_price: "",
                    event_group_id: "",
                    taxonomy: "",
                    currency: "",
                    quantity: ""
                  }
                }
              }
            },
            output: { type: "void", properties: {} },
            variables: []
          }
        },
        command: onSelect
      }
    ]
  });

  menu.items.sort((a, b) => {
    if (a.label!.toLowerCase() < b.label!.toLowerCase()) {
      return -1;
    }
    if (a.label!.toLowerCase() > b.label!.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return menu as IActionAdderMenuItem<IActionAdderMenuItemData>;
}

function getCode2ActionsSubMenuItems(
  flowType: IFlowType,
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
): IActionAdderMenuItem<IActionAdderMenuItemData> {
  const menu: {
    label: string;
    icon: string;
    items: IActionAdderMenuItem<IActionAdderMenuItemData>[];
  } = {
    label: "Peaka Actions",
    icon: "c2-icons-publish",
    items: []
  };

  const feActions: IActionAdderMenuItem<IActionAdderMenuItemData>[] = [
    {
      label: "Show Notification",
      icon: "c2-icons-notification",
      data: {
        type: "action",
        actionType: "showNotification"
      },
      command: onSelect
    },
    {
      label: "Set Page Variables",
      icon: "c2-icons-settings-hexagon",
      data: {
        actionType: "setPageVariables",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Set Component Property",
      icon: "c2-icons-settings-hexagon",
      data: {
        actionType: "setComponentProperty",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Reload Component Data",
      icon: "c2-icons-settings-hexagon",
      data: {
        actionType: "reloadComponentData",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Set App Variables",
      icon: "c2-icons-settings-hexagon",
      data: {
        actionType: "setAppVariables",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Set Flow Variables",
      icon: "c2-icons-transformation",
      data: {
        actionType: "transformation",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Log",
      icon: "c2-icons-code-circle",
      data: {
        actionType: "log",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Navigate",
      icon: "c2-icons-code-circle",
      data: {
        actionType: "navigate",
        type: "action"
      },
      command: onSelect
    }
  ];

  const beActions: IActionAdderMenuItem<IActionAdderMenuItemData>[] = [
    {
      label: "Set Flow Variables",
      icon: <FontAwesomeIcon icon={faArrowProgress} className="mr-2 text-50" />,
      data: {
        actionType: "transformation",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Transformation",
      icon: <FontAwesomeIcon icon={faArrowsRotate} className="mr-2  text-50" />,
      data: {
        actionType: "transform",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "HTTP Request",
      icon: <FontAwesomeIcon icon={faGlobe} className="mr-2  text-50" />,
      data: {
        actionType: "http",
        type: "action"
      },
      command: onSelect
    },
    {
      label: "Reusable Flow",
      icon: (
        <FontAwesomeIcon icon={faDiagramNested} className="mr-2  text-50" />
      ),
      data: {
        actionType: "executeBackendFlow",
        type: "reusableFlow"
      },
      command: onSelect
    },
    {
      label: "Webhook Response",
      icon: <FontAwesomeIcon icon={faWebhook} className="mr-2  text-50" />,
      data: {
        actionType: "webhook:response",
        type: "action"
      },
      command: onSelect
    }
  ];

  switch (flowType) {
    case "fe":
      menu.items.push(
        ...feActions,
        ...beActions.filter((flow) => {
          return (
            flow.data?.actionType !== "webhook:response" &&
            flow.data?.actionType !== "transformation"
          );
        })
      );
      break;
    case "be":
      menu.items.push(...beActions);
      break;
    case "reusable":
      menu.items.push(
        ...beActions.filter(
          (flow) => flow.data?.actionType !== "webhook:response"
        )
      );
      break;
  }

  return menu;
}

function getUtilitiesSubMenuItems(
  onSelect: (
    event: IActionAdderMenuItemCommand<IActionAdderMenuItemData>
  ) => void
) {
  return {
    label: "Utilities",
    items: [
      {
        label: "Switch / Case",
        icon: "c2-icons-duplicate",
        data: {
          type: "switch",
          actionType: "switch"
        },
        command: onSelect
      },
      {
        label: "Loops",
        icon: "c2-icons-loop",
        items: [
          {
            label: "Loop Over List",
            data: {
              type: "loop",
              actionType: "loopList"
            },
            command: onSelect
          },
          {
            label: "Loop Forever",
            data: {
              type: "loop",
              actionType: "loopForever"
            },
            command: onSelect
          },
          {
            label: "Break",
            icon: "c2-icons-flag",
            data: {
              type: "break",
              actionType: "break"
            },
            command: onSelect
          }
        ]
      },
      {
        label: "Fail",
        icon: "c2-icons-pending",
        data: {
          type: "fail",
          actionType: "fail"
        },
        command: onSelect
      },
      {
        label: "Success",
        icon: "c2-icons-check",
        data: {
          type: "success",
          actionType: "success"
        },
        command: onSelect
      }
    ]
  };
}

const nodeTypes = {
  trigger: TriggerNode,
  backendTrigger: BackendTriggerNode,
  action: ActionNode,
  reusableFlow: ReusableFlowNode,
  adder: ActionAdderNode,
  switch: SwitchNode,
  callable: CallableNode,
  fail: ActionNode,
  success: ActionNode,
  loop: LoopNode,
  endLoop: EndLoopNode,
  break: BreakNode,
  switchCase: SwitchCaseNode,
  defaultCase: DefaultCaseNode
};

const edgeTypes = {
  adder: ActionAdderEdge
};

function getEmptyFEFlowManifest(
  config: INewComponentFlowConfig
): IFrontendFlow {
  const { id, name, relatedComponentId, autoGenerated, autoGenerationContext } =
    config;
  return {
    id,
    name,
    componentId: relatedComponentId,
    type: "fe",
    autoGenerated,
    autoGenerationContext,
    description: "",
    draft: {
      trigger: {
        type: "",
        data: {
          label: "",
          executionType: "fe",
          actionType: "trigger",
          params: {},
          output: {
            type: "void",
            properties: null
          },
          variables: [],
          hasEventData: false
        }
      },
      nodes: {}
    },
    live: null,
    executions: {}
  };
}

function getEmptyBEFlowManifest(config: {
  id: string;
  name: string;
}): IBackendFlow {
  const { id, name } = config;
  return {
    id,
    name,
    type: "be",
    description: "",
    draft: {
      trigger: {
        type: "",
        data: {
          label: "",
          executionType: "be",
          actionType: "trigger",
          params: {},
          output: {
            type: "void",
            properties: null
          },
          variables: []
        }
      },
      nodes: {}
    },
    live: null,
    executions: {}
  };
}

export function getEmptyWebhookFlowManifest(config: {
  id: string;
  name: string;
  method: WebhookMethodParameterOption;
  url: string;
  description: string;
}): IBackendFlow {
  const { id, name, url, method, description } = config;
  const webhookData = cloneDeep(flowNodeDefaults.webhook);
  webhookData.params.method = method;
  webhookData.params.url = url;

  const webhookResponseData = cloneDeep(flowNodeDefaults["webhook:response"]);
  const webhookResponseId = uuidv4();

  const draft = {
    trigger: {
      type: "webhook" as INodeType,
      data: webhookData
    },
    nodes: {
      [webhookResponseId]: {
        id: webhookResponseId,
        type: "action" as INodeType,
        name: "webhookResponse",
        data: webhookResponseData,
        next: []
      }
    }
  };

  const flow: IFlow = {
    id,
    name,
    type: "be",
    description,
    draft,
    live: draft,
    executions: {}
  };
  const executions = getFlowExecutions(flow);
  flow.executions = executions;

  return flow;
}

export function getEmptyScheduledFlowManifest(config: {
  id: string;
  name: string;
  description: string;
}): IBackendFlow {
  const { id, name, description } = config;

  const scheduledData = cloneDeep(flowNodeDefaults.scheduled);
  const draft = {
    trigger: {
      type: "scheduled" as INodeType,
      data: scheduledData
    },
    nodes: {}
  };

  const flow: IFlow = {
    id,
    name,
    type: "be",
    description,
    draft,
    live: draft,
    executions: {}
  };

  const executions = getFlowExecutions(flow);
  flow.executions = executions;

  return flow;
}

function getEmptyReusableFlowManifest(config: {
  id: string;
  name: string;
  description: string;
}): IReusableFlow {
  const { id, name, description } = config;
  return {
    id,
    name,
    type: "reusable",
    description,
    draft: {
      trigger: {
        type: "callable",
        data: {
          label: "Callable",
          executionType: "be",
          actionType: "callable",
          params: {
            callableInputs: []
          },
          output: {
            type: "void",
            properties: null
          },
          variables: []
        }
      },
      nodes: {
        [ConstantNodeIds.EndNode]: {
          id: ConstantNodeIds.EndNode,
          type: "action",
          name: "end",
          data: {
            label: "End",
            executionType: "both",
            actionType: "end",
            params: {
              output: ""
            },
            output: {
              type: "void",
              properties: null
            },
            variables: []
          },
          next: []
        }
      }
    },
    live: null,
    executions: {}
  };
}

function toCamelCase(str: string): string {
  str = str.replace(/[^a-zA-Z0-9]/g, " ");
  const words = str.split(" ");
  let camelCase = "";

  for (let i = 0; i < words.length; i++) {
    if (i === 0) {
      camelCase += words[i].toLowerCase();
    } else {
      camelCase +=
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
  }

  return camelCase;
}

function createActionName(actionType: string) {
  switch (actionType) {
    case "webhook:response":
      return "webhookResponse";
    case "db:get":
      return "getRecord";
    case "db:query":
      return "getAllRecords";
    case "db:insert":
      return "addRecord";
    case "db:update":
      return "updateRecord";
    case "db:delete":
      return "deleteRecord";
    default:
      return toCamelCase(actionType);
  }
}

const tooltipContent: {
  [key in IActionType]: string;
} = {
  break: "Break out of the current loop based on a condition",
  "webhook:response": "Return a response from a webhook endpoint",
  http: "Make an HTTP request",
  "db:get": "Get a record from a table or query",
  "db:query": "Get all records from a table or query",
  "db:insert": "Add a record to a table",
  "db:bulkInsert": "Add multiple records to a table",
  "db:update": "Update a record in a table",
  "db:bulkUpdate": "Update records in a table",
  "db:delete": "Delete a record from a table",
  "db:bulkDelete": "Delete records from a table",
  showNotification: "Show a Toast Notification",
  setVariable: "Set the value of a variable",
  setPageVariables: "Set the value of one or more page variables",
  setAppVariables: "Set the value of one or more app variables",
  setComponentProperty: "Set the value of one or more component properties",
  reloadComponentData: "Reload the data from datasource",
  log: "Log something to the browser console",
  navigate: "Navigate to another page",
  switch: "Execute different branches of logic based on conditions",
  loopList: "Execute some actions for each item of a list",
  loopForever:
    "Execute some actions repeatedly until a break node is encountered",
  endLoop: "",
  callable: "Set the parameters for this reusable flow",
  webhook: "",
  fail: "End the flow with fail status",
  success: "End the flow with success status",
  end: "",
  executeBackendFlow: "Run a reusable flow",
  customAction: "",
  transformation: "Set the value of one or more flow variables",
  trigger: "",
  scheduled: "",
  transform: "Transform an object or array of objects",
  "insider:upsert": "Upsert for Insider Connector"
} as const;

export {
  getStartNode,
  getActionNodeMenuItems,
  getTriggerNodeOptions,
  getEmptyFEFlowManifest,
  getEmptyBEFlowManifest,
  getEmptyReusableFlowManifest,
  createActionName,
  getEndLoopNodeIdFor,
  getLoopNodeIdFor,
  isEndLoopId,
  nodeTypes,
  edgeTypes,
  tooltipContent
};
