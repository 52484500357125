import { useContext } from "react";
import OnboardingContext from "../context";

const useEvaluationContext = () => {
  const { evaluationContext, setEvaluationContext } =
    useContext(OnboardingContext);

  return { evaluationContext, setEvaluationContext };
};

export default useEvaluationContext;
