import * as Frigade from "@frigade/react";
import { getDynamicConfigValue } from "../../utils/dynamicConfig";
import FrigadeOnboarding from "./FrigadeOnboarding";
import { useAppSelector } from "../../hooks";
import { selectUser } from "../../../features/auth/authSlice";
import { useState } from "react";
import type { EvaluationContext } from "../Onboarding/types";
import { OnboardingProvider } from "../Onboarding/context";

const FrigadeProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useAppSelector(selectUser);
  const [evaluationContext, setEvaluationContext] = useState<EvaluationContext>(
    {}
  );

  return (
    <>
      <OnboardingProvider value={{ evaluationContext, setEvaluationContext }}>
        <Frigade.Provider
          userId={user ? user.username : ""}
          apiKey={getDynamicConfigValue("FRIGADE_PUBLIC_API_KEY")}
        >
          {children}
          <FrigadeOnboarding />
        </Frigade.Provider>
      </OnboardingProvider>
    </>
  );
};

export default FrigadeProvider;
