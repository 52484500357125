import { Routes, Route, Navigate } from "react-router-dom";
//IMPORTANT DO NOT CHANGE IMPORT ORDER: Studio Import should be before data imports, if studio is imported after hue sql editor breaks
import { Fragment, Suspense, lazy } from "react";
import { BlockUI } from "primereact/blockui";
import StudioLayoutSkeleton from "../components/Skeletons/StudioLayoutSkeleton";
import DataModelSkeleton from "../components/Skeletons/DataModelSkeleton";
import DataModelTableListSkeleton from "../components/Skeletons/DataModelTableListSkeleton";
import DataModelTableSkeleton from "../components/Skeletons/DataModelTableSkeleton";
import UIEditorSkeleton from "../components/Skeletons/UIEditorSkeleton";
import HomeSkeleton from "../components/Skeletons/HomeSkeleton";
import BillingSkeleton from "../components/Skeletons/BillingSkeleton";
import SettingSkeleton from "../components/Skeletons/SettingSkeleton";
import ApplicationsSkeleton from "../components/Skeletons/ApplicationsSkeleton";
import ParentRoute from "./ParentRoute";
import FlowsSkeleton from "../components/Skeletons/FlowsSkeleton";
import ApiKeys from "../../features/apiKeys/ApiKeys";
import ProtectedRoutes from "./ProtectedRoutes";
import AccessDenied from "../../features/accessDenied/AccessDenied";

const FlowsPageLayout = lazy(
  () => import("../../features/flowsPage/FlowsPageLayout")
);
const Webhooks = lazy(
  () => import("../../features/flowsPage/components/WebhooksPage/Webhooks")
);
const ReusableFlows = lazy(
  () =>
    import(
      "../../features/flowsPage/components/ReusableFlowsPage/ReusableFlows"
    )
);

const ScheduledFlows = lazy(
  () =>
    import(
      "../../features/flowsPage/components/ScheduledFlowsPage/ScheduledFlows"
    )
);

const FlowViewLayout = lazy(
  () => import("../../features/flowsPage/components/FlowPage/FlowPage")
);

const FlowHistory = lazy(
  () => import("../../features/flowsPage/components/FlowPage/FlowHistory")
);
const Studio = lazy(() => import("../../features/studio/Studio"));
const Home = lazy(() => import("../../features/home/home"));
const UIEditorLayout = lazy(
  () => import("../../features/uiEditor/uiEditorLayout")
);
const DataModel = lazy(() => import("../../features/dataModel/dataModel"));
const DataModelQuery = lazy(
  () => import("../../features/dataModelQuery/dataModelQuery")
);
const DataModelQueryList = lazy(
  () => import("../../features/dataModelQuery/dataModelQueryList")
);

const DataModelSharedQuery = lazy(
  () => import("../../features/dataModelSharedQuery/dataModelSharedQuery")
);
const DataModelTable = lazy(
  () => import("../../features/dataModelTable/dataModelTable")
);
const DataModelTableList = lazy(
  () => import("../../features/dataModelTable/dataModelTableList")
);
const Billing = lazy(() => import("../../features/subscription/billing"));
const Applications = lazy(
  () => import("../../features/applications/Applications")
);
const Login = lazy(() => import("../../features/auth/Login"));
const SignUp = lazy(() => import("../../features/auth/SignUp"));
const ForgotPassword = lazy(() => import("../../features/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../../features/auth/ResetPassword"));
const Connections = lazy(
  () => import("../../features/connections/Connections")
);
const SettingsLayout = lazy(
  () => import("../../features/appSettings/AppSettings")
);
const ConnectLayout = lazy(
  () => import("../../features/connect/ConnectLayout")
);
const GeneralSettings = lazy(
  () => import("../../features/general-settings/GeneralSettings")
);
const OauthCallback = lazy(
  () => import("../../features/OauthCallback/OauthCallback")
);
const PostCheckout = lazy(
  () => import("../../features/subscription/postCheckout")
);
const Settings = lazy(() => import("../../features/settings/Settings"));
const AdvancedSettings = lazy(
  () => import("../../features/advancedSettings/AdvancedSettings")
);
const NotFound = lazy(() => import("../../features/notFound/NotFound"));

const ApiSdk = lazy(() => import("../../features/connect/ApiSdk/ApiSdk"));

const DBDrivers = lazy(
  () => import("../../features/connect/DBDrivers/DBDrivers")
);

const BITools = lazy(() => import("../../features/connect/BITools/BITools"));

const FlowPageLayout = lazy(
  () => import("../../features/flowsPage/components/FlowPage/FlowPageLayout")
);

const C2Router = () => {
  return (
    <Fragment>
      <Routes>
        <Route
          path="login/"
          element={
            <Suspense fallback={<BlockUI />}>
              <Login />
            </Suspense>
          }
        >
          <Route
            path=":errorCode"
            element={
              <Suspense fallback={<BlockUI />}>
                <Login />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="forgot-password/"
          element={
            <Suspense fallback={<BlockUI />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="account-verification/:code"
          element={
            <Suspense fallback={<BlockUI />}>
              <ResetPassword reset={false} />
            </Suspense>
          }
        />
        <Route
          path="password-reset/:code"
          element={
            <Suspense fallback={<BlockUI />}>
              <ResetPassword reset={true} />
            </Suspense>
          }
        />
        <Route
          path="signup/"
          element={
            <Suspense fallback={<BlockUI />}>
              <SignUp />
            </Suspense>
          }
        >
          <Route
            path=":errorCode"
            element={
              <Suspense fallback={<BlockUI />}>
                <SignUp />
              </Suspense>
            }
          />
        </Route>
        <Route path="/" element={<ParentRoute />}>
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <Suspense fallback={<HomeSkeleton />}>
                <Home />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <ProtectedRoutes roleRequired={["DEVELOPER", "USER"]}>
                  <Suspense fallback={<ApplicationsSkeleton />}>
                    <Applications />
                  </Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="applications/:page"
              element={
                <Suspense fallback={<ApplicationsSkeleton />}>
                  <Applications />
                </Suspense>
              }
            />
            <Route
              path="billing"
              element={
                <Suspense fallback={<BillingSkeleton />}>
                  <Billing />
                </Suspense>
              }
            />
            <Route
              path="settings"
              element={
                <Suspense fallback={<SettingSkeleton />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              path="advanced-settings"
              element={
                <Suspense fallback={<SettingSkeleton />}>
                  <AdvancedSettings />
                </Suspense>
              }
            />
            <Route
              path="post-checkout/:status"
              element={
                <Suspense fallback={<BlockUI />}>
                  <PostCheckout />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="studio/:appId/*"
            element={
              <Suspense fallback={<StudioLayoutSkeleton />}>
                <Studio />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="data-model" />} />
            <Route
              path="flows"
              element={<ProtectedRoutes roleRequired={["DEVELOPER", "USER"]} />}
            >
              <Route index element={<Navigate to="api-webhook" />} />
              <Route
                path=":id"
                element={
                  <Suspense fallback={<BlockUI />}>
                    <FlowPageLayout />
                  </Suspense>
                }
              >
                <Route index element={<FlowViewLayout />} />
                <Route path="history" element={<FlowHistory />} />
              </Route>
              <Route
                element={
                  <Suspense fallback={<FlowsSkeleton />}>
                    <FlowsPageLayout />
                  </Suspense>
                }
              >
                <Route
                  path="api-webhook"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <Webhooks />
                    </Suspense>
                  }
                />
                <Route
                  path="reusable-flows"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <ReusableFlows />
                    </Suspense>
                  }
                />
                <Route
                  path="scheduled-flows"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <ScheduledFlows />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route
              path="data-model/*"
              element={
                <Suspense fallback={<DataModelSkeleton />}>
                  <DataModel />
                </Suspense>
              }
            >
              <Route
                path="tables/*"
                element={
                  <Suspense fallback={<DataModelTableListSkeleton />}>
                    <DataModelTableList />
                  </Suspense>
                }
              >
                <Route
                  path=":tableId"
                  element={
                    <Suspense fallback={<DataModelTableSkeleton />}>
                      <DataModelTable />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="queries/*"
                element={
                  <Suspense fallback={<DataModelTableListSkeleton />}>
                    <DataModelQueryList />
                  </Suspense>
                }
              >
                <Route
                  path=":queryId"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <DataModelQuery />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="shared-queries/*"
                element={
                  <Suspense fallback={<DataModelTableListSkeleton />}>
                    <DataModelSharedQuery />
                  </Suspense>
                }
              >
                <Route
                  path=":queryId"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <DataModelQuery />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="*" element={<Navigate replace to="tables" />} />
            </Route>
            <Route
              path="connect"
              element={
                <Suspense fallback={<BlockUI />}>
                  <ConnectLayout />
                </Suspense>
              }
            />
            <Route
              path="connect/bi-tools"
              element={
                <Suspense fallback={<BlockUI />}>
                  <BITools />
                </Suspense>
              }
            />
            <Route
              path="connect/api-sdk"
              element={
                <Suspense fallback={<BlockUI />}>
                  <ApiSdk />
                </Suspense>
              }
            />
            <Route
              path="connect/db-drivers"
              element={
                <Suspense fallback={<BlockUI />}>
                  <DBDrivers />
                </Suspense>
              }
            />
            <Route
              path="ui-editor"
              element={<ProtectedRoutes roleRequired={["DEVELOPER", "USER"]} />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<UIEditorSkeleton />}>
                    <UIEditorLayout />
                  </Suspense>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="data-model" />} />
            <Route
              path="settings"
              element={<ProtectedRoutes roleRequired={["DEVELOPER", "USER"]} />}
            >
              <Route
                path="*"
                element={
                  <Suspense fallback={<BlockUI />}>
                    <SettingsLayout />
                  </Suspense>
                }
              >
                <Route
                  path="connections"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <Connections />
                    </Suspense>
                  }
                />
                <Route
                  path="api-keys"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <ApiKeys />
                    </Suspense>
                  }
                />
                <Route
                  path="general-settings"
                  element={
                    <Suspense fallback={<BlockUI />}>
                      <GeneralSettings />
                    </Suspense>
                  }
                />
                <Route path="*" element={<Navigate to="connections" />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="oauth/result/"
          element={
            <Suspense fallback={<BlockUI />}>
              <OauthCallback />
            </Suspense>
          }
        />
        <Route
          path="denied"
          element={
            <Suspense fallback={<BlockUI />}>
              <AccessDenied />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<BlockUI />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default C2Router;
