import { skipToken } from "@reduxjs/toolkit/query";
import { useGetApiKeysQuery } from "../../app/services/apiKey";
import useAppId from "../../app/utils/useAppId";
import { classNames } from "primereact/utils";
import moment from "moment";
import { cloneDeep } from "lodash";
import ApiKeyRow from "./ApiKeyRow";
import { useState } from "react";
import ApiKeyForm from "./ApiKeyForm";
import { Skeleton } from "primereact/skeleton";
import EmptyApiKey from "../../app/assets/svg/empty-api-key.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
  PeakaButton,
  PeakaInputText
} from "@code2io/fe-studio-component-library";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

export const NEW_API_KEY_PREFIX = "API Key ";

const ApiKeys = () => {
  const appId = useAppId();
  const { data: apiKeys } = useGetApiKeysQuery(appId ? { appId } : skipToken);
  const [searchText, setSearchText] = useState("");
  const [generateApiKeyDialogVisible, setGenerateApiKeyDialogVisible] =
    useState(false);
  const [apiKeyNumber, setApiKeyNumber] = useState("");

  function parseDocumentNumber(documentName: string) {
    const regex = /\d+/; // matches one or more digits
    const matches = documentName.match(regex);
    if (matches) {
      return parseInt(matches[0]);
    }
    return 1;
  }

  return (
    <div
      className="flex flex-column bg-white-600 h-full overflow-scroll"
      style={{
        paddingLeft: "4rem",
        paddingTop: "2.5rem",
        paddingRight: "4rem"
      }}
    >
      <div className="flex justify-content-between align-items-center">
        <div className={"api-keys-header"}>API Keys</div>

        <div className="flex justify-content-center align-items-center gap-3">
          <PeakaInputText
            leftIcon={<FontAwesomeIcon icon={faSearch} />}
            wrapperClassName="w-full max-w-14rem"
            autoComplete="off"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <PeakaButton
            label="API Key"
            icon={<FontAwesomeIcon icon={faPlus} />}
            iconPosition="left"
            onClick={() => {
              const newApiKeyNumbers = apiKeys
                ?.filter((apiKey) =>
                  apiKey.name.includes(NEW_API_KEY_PREFIX.trim())
                )
                .map((apiKey) => parseDocumentNumber(apiKey.name))
                .sort((a, b) => b - a);

              if (!newApiKeyNumbers) {
                return;
              }

              const generatedApiKeyNumber =
                newApiKeyNumbers.length === 0
                  ? `${NEW_API_KEY_PREFIX}`.trim()
                  : `${NEW_API_KEY_PREFIX} #${newApiKeyNumbers[0] + 1}`;

              setApiKeyNumber(generatedApiKeyNumber);
              setGenerateApiKeyDialogVisible(true);
            }}
            style={{ textWrap: "nowrap" }}
          />

          <ApiKeyForm
            appId={appId}
            isGenerate={true}
            className="h-full w-full"
            onCancel={() => setGenerateApiKeyDialogVisible(false)}
            onHide={() => setGenerateApiKeyDialogVisible(false)}
            visible={generateApiKeyDialogVisible}
            apiKey={undefined}
            newApiKeyNumber={apiKeyNumber}
          />
        </div>
      </div>
      <div
        className={classNames(
          "api-keys-sub-header",
          "flex justify-content-start align-items-center"
        )}
      >
        Generate and control API Keys for this project.
      </div>

      {apiKeys ? (
        apiKeys.length === 0 ? (
          <div
            className={classNames(
              "api-keys-loading-container",
              "overflow-auto flex flex-column justify-content-center align-items-center pb-2"
            )}
            style={{ gap: "0.5rem", marginTop: "8rem" }}
          >
            <img src={EmptyApiKey} alt="no-connection" />
            <h4 className="mt-1 text-white-50 text-semibold text-xl">
              No API Keys Created
            </h4>
            <p
              className="mt-1 text-white-50 text-base font-normal"
              style={{ lineHeight: "1.5rem" }}
            >
              Create a new API Key to distribute your data.
            </p>
            <PeakaButton
              onClick={() => setGenerateApiKeyDialogVisible(true)}
              icon={<FontAwesomeIcon icon={faPlus} />}
              iconPosition="left"
              label="Add First API Key"
            />
          </div>
        ) : (
          <div
            className={classNames(
              "api-keys-container",
              "overflow-auto flex flex-column justify-content-start align-items-center"
            )}
          >
            {apiKeys.length !== 0 && (
              <ApiKeyRow appId={appId} isHeaderRow={true} />
            )}
            {cloneDeep(apiKeys)
              .sort(
                (a, b) =>
                  moment(a.createdAt).unix() - moment(b.createdAt).unix()
              )
              .filter((apiKey) =>
                searchText === "" ? true : apiKey.name.includes(searchText)
              )
              .map((apiKey, index) => (
                <ApiKeyRow
                  key={apiKey.id}
                  appId={appId}
                  apiKey={apiKey}
                  index={index}
                />
              ))}
          </div>
        )
      ) : (
        <div
          className={classNames(
            "api-keys-loading-container",
            "overflow-auto flex flex-column justify-content-start align-items-center"
          )}
        >
          <Skeleton height="3rem" className="w-full" />
          <Skeleton height="3rem" className="w-full" />
          <Skeleton height="3rem" className="w-full" />
          <Skeleton height="3rem" className="w-full" />
          <Skeleton height="3rem" className="w-full" />
          <Skeleton height="3rem" className="w-full" />
        </div>
      )}
    </div>
  );
};

export default ApiKeys;
